import { graphql, useStaticQuery } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Col, Container, Dropdown, Row,
} from 'react-bootstrap'
import CookingTipsPopup from '../components/common/popup-cooking-tips'
import RecipeBlock from '../components/common/recipe-block'
import Layout from '../components/layout'
import MainCTA from '../components/sections/main-cta'
import RecipeCarousel from '../components/sections/recipe-carousel'
import Seo from '../components/seo'

const RecipeArchive = ({
  location,
  pageContext: { },
}) => {
  const data = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          recipesPageOptions {
            recipePageOptions {
              carousel {
                recipes {
                  ... on WpRecipe {
                    id
                  }
                }
              }
              featuredCategory {
                buttonText
                category {
                  slug
                }
              }
              seasonalHits {
                buttonText
                recipes {
                  ... on WpRecipe {
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
      allWpRecipe(sort: { fields: date, order: DESC }) {
				nodes {
          id
          title
          slug
          excerpt
          recipes {
            cookTime
            prepTime
            chefCollaborator {
              ... on WpCollaborator {
                id
                title
              }
            }
            madeWith2 {
              ... on WpCut {
                slug
                title
              }
            }
            serves
            shareEnabled
          }
          types {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  carouselImage: gatsbyImageData(
                    quality: 90
                    height: 746
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    transformOptions: {
                      cropFocus: CENTER,
                    }
                  )
                  thumbnailImage: gatsbyImageData(
                    quality: 90
                    width: 308
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    transformOptions: {
                      cropFocus: CENTER,
                    }
                  )
                }
              }
            }
          }
        }
			}
    }
  `)

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
    gsap.to(window, { duration: 0.35, scrollTo: 0 })

    // ScrollTrigger to stickfilters to the top
    ScrollTrigger.create({
      trigger: '.recipe-archive__filters',
      start: 'top top+=65',
      endTrigger: '.recipe-archive__recipe-grid',
      end: 'bottom bottom',
      pin: true,
      pinSpacing: false,
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  const recipeOptions = data.allWp.nodes[0].recipesPageOptions.recipePageOptions
  const seasonalHitsSlug = recipeOptions.seasonalHits.buttonText.toLowerCase().replace(/\s+/g, '-')

  const carouselRecipes = recipeOptions.carousel.recipes.map(({ id }) => data.allWpRecipe.nodes.find((recipe) => recipe.id === id))

  // extract all unique madeWith2 slugs and titles from recipe nodes
  // and sort them alphabetically by title
  const [allMadeWith2] = React.useState(() => {
    const uniqueSlugs = new Set()
    const result = data.allWpRecipe.nodes.reduce((acc, node) => {
      node.recipes.madeWith2.forEach((madeWith) => {
        if (madeWith.slug && !uniqueSlugs.has(madeWith.slug)) {
          uniqueSlugs.add(madeWith.slug)
          acc.push({
            slug: madeWith.slug,
            title: madeWith.title,
          })
        }
      })
      return acc
    }, []).sort((a, b) => a.title.localeCompare(b.title))
    result.unshift({ slug: 'all', title: 'All' })
    return result
  })

  const { type, cut } = queryString.parse(location.search)
  const [activeButton, setActiveButton] = useState(type || 'dropdown')
  const [handleValue, setHandleValue] = useState(cut || 'all')
  const [typeValue, setTypeValue] = useState(type || 'all')

  const handleSelect = (e, itemType) => {
    const newPath = itemType === 'handle' ? `/recipes/?cut=${e}` : `/recipes/?type=${e}`
    if (typeof window !== 'undefined') {
      window.history.pushState({ path: newPath }, '', newPath)
    }
    if (itemType === 'handle') {
      setHandleValue(e)
      setTypeValue('all')
      setActiveButton('dropdown')
    } else {
      setTypeValue(e)
      setHandleValue('all')
      setActiveButton(e)
    }
  }

  const filterRecipes = useCallback(() => {
    if (typeValue === 'all' && handleValue === 'all') {
      return data.allWpRecipe.nodes
    }

    if (typeValue === seasonalHitsSlug) {
      return recipeOptions.seasonalHits.recipes.map(({ id }) => data.allWpRecipe.nodes.find((recipe) => recipe.id === id))
    }

    return data.allWpRecipe.nodes.filter((recipe) => {
      if (typeValue !== 'all') {
        return recipe.types.nodes.some(({ slug }) => slug === typeValue)
      }

      if (handleValue !== 'all') {
        return recipe.recipes.madeWith2.some(({ slug }) => slug === handleValue)
      }
    })
  }, [data.allWpRecipe.nodes, handleValue, typeValue, recipeOptions, seasonalHitsSlug])

  const filteredRecipes = filterRecipes()

  // Tell the slideshow to autoplay after the popup is closed
  const [autoplay, setAutoplay] = useState(false)
  const onHandleClose = () => {
    setAutoplay(true)
  }

  return (
    <Layout className="recipe-archive">
      <Seo title="Recipes" />
      <RecipeCarousel autoplay={autoplay} recipes={carouselRecipes} />
      <section className="py-1 py-md-2 recipe-archive__recipe-grid">
        <div className="recipe-archive__filters bg-primary py-12">
          <Container className="d-flex justify-content-start justify-content-lg-center flex-wrap">
            <Dropdown onSelect={(e) => handleSelect(e, 'handle')} className={`mb-12 mb-lg-0 me-12 me-lg-1 ${handleValue !== 'all' ? 'active' : ''}`}>
              <Dropdown.Toggle variant="outline-light" className={`text-uppercase rounded-pill px-2 fw-normal ${activeButton === 'dropdown' ? 'active' : ''}`}>
                { handleValue === 'all' ? 'All Cuts' : handleValue.replace(/-/g, ' ') }
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  allMadeWith2.map((element, index) => (
                    <Dropdown.Item as="button" eventKey={element.slug} key={index}>
                      {element.title}
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="outline-light" className={`text-uppercase rounded-pill px-2 fw-normal mb-12 mb-lg-0 me-12 me-lg-1 ${activeButton === recipeOptions.featuredCategory.category.slug ? 'active' : ''}`} onClick={(e) => handleSelect(recipeOptions.featuredCategory.category.slug, 'type')}>
              { recipeOptions.featuredCategory.buttonText }
            </Button>
            <Button variant="outline-light" className={`text-uppercase rounded-pill px-2 fw-normal ${activeButton === seasonalHitsSlug ? 'active' : ''}`} onClick={(e) => handleSelect(seasonalHitsSlug, 'type')}>
              {recipeOptions.seasonalHits.buttonText}
            </Button>
          </Container>
        </div>
        <Container className="pb-2 recipe-blocks-wrapper">
          <Row className="recipe-blocks recipe-blocks--rainbow text-center g-12 g-sm-1 justify-content-start mt-0">
            {
              filteredRecipes.map((recipe, index) => (
                <Col xs={6} lg={4} xxl={3} className="recipe-column" key={`recipe-block-${index}`}>
                  <RecipeBlock node={recipe} justify="center" />
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
      <MainCTA />
      <CookingTipsPopup onHandleClose={onHandleClose} />
    </Layout>
  )
}

export default RecipeArchive
