import React, { useEffect, useRef, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Glide from '@glidejs/glide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'
import CarouselGlideArrowsSquare from '../common/carousel-glide-arrows-square'

const RecipeCarousel = ({ autoplay, recipes }) => {
  const sliderRef = useRef(null)

  const slider = new Glide('.glide-recipe-carousel', {
    type: 'carousel',
    autoplay: autoplay ? 5000 : false,
    gap: 0,
  })

  const numImages = recipes.length
  let numImagesLoaded = 0
  const handleImagesLoaded = () => {
    numImagesLoaded++
    if (numImages === numImagesLoaded) {
      slider.update()
    }
  }

  useEffect(() => {
    if (numImages <= 1) {
      return
    }
    slider.mount()
    sliderRef.current = slider
  }, [])

  useEffect(() => {
    if (autoplay && sliderRef.current) {
      sliderRef.current.update({ autoplay: 5000 })
    }
  }, [autoplay])

  return (
    <header className="recipe-archive__carousel py-1 py-md-2 text-primary">
      <Container className="position-relative" style={{ zIndex: 1 }}>
        <h1 className="fw-bold mb-1 mb-md-0">
          <span>R</span>
          ecipes
        </h1>
      </Container>
      <Container fluid className="glide glide-recipe-carousel px-0">
        <div className="glide__track position-relative" data-glide-el="track">
          <div className="glide__slides">
            {
              recipes.map((node, index) => (
                <div className="glide__slide" key={`carousel-${index}`}>
                  <Container className="recipe-archive__carousel__grid d-md-grid h-100">
                    <div className="recipe-archive__carousel__grid__image">
                      <GatsbyImage
                        image={getImage(node.featuredImage?.node?.localFile?.childImageSharp?.carouselImage)}
                        alt={`Meati™ ${node.title} recipe`}
                        className="rounded-6 ratio ratio-5x4 mb-12 mb-md-0"
                        onLoad={handleImagesLoaded}
                      />
                    </div>
                    <div className="recipe-archive__carousel__grid__text d-md-flex flex-column justify-content-between pb-lg-4">
                      <div>
                        {node.recipes.chefCollaborator
                            && (
                            <div className="fw-bold fs-5">
                              MEATI™ x {node.recipes.chefCollaborator.title}
                            </div>
                            )}
                        <h2 className="mb-1 fw-bold h2">{node.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                      </div>
                      <ButtonLink btnLink={`/recipes/${node.slug}`} btnText="See Recipe" />
                    </div>
                  </Container>
                </div>
              ))
            }
          </div>
          <Container className="glide__arrows__container recipe-archive__carousel__grid mt-12 mt-md-0 d-lg-grid">
            <div className="recipe-archive__carousel__grid__text position-relative">
              <CarouselGlideArrowsSquare />
            </div>
          </Container>
        </div>
      </Container>
    </header>
  )
}

export default RecipeCarousel
